:root {
  font-family: var(--spectrum-alias-body-text-font-family, var(--spectrum-global-font-family-base));
  font-size: var(--spectrum-alias-font-size-default, var(--spectrum-global-dimension-font-size-100));
}

:host {
  font-family: var(--spectrum-alias-body-text-font-family, var(--spectrum-global-font-family-base));
  font-size: var(--spectrum-alias-font-size-default, var(--spectrum-global-dimension-font-size-100));
}

.spectrum:lang(ar) {
  font-family: var(--spectrum-alias-font-family-ar, var(--spectrum-global-font-font-family-ar));
}

.spectrum:lang(he) {
  font-family: var(--spectrum-alias-font-family-he, var(--spectrum-global-font-font-family-he));
}

.spectrum:lang(zh-Hans) {
  font-family: var(--spectrum-alias-font-family-zhhans, var(--spectrum-global-font-font-family-zhhans));
}

.spectrum:lang(zh-Hant), .spectrum:lang(zh) {
  font-family: var(--spectrum-alias-font-family-zh, var(--spectrum-global-font-font-family-zh));
}

.spectrum:lang(ko) {
  font-family: var(--spectrum-alias-font-family-ko, var(--spectrum-global-font-font-family-ko));
}

.spectrum:lang(ja) {
  font-family: var(--spectrum-alias-font-family-ja, var(--spectrum-global-font-font-family-ja));
}

.spectrum-Heading--sizeXXXL {
  font-size: var(--spectrum-heading-xxxl-text-size, var(--spectrum-alias-heading-xxxl-text-size));
  font-style: var(--spectrum-heading-xxxl-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-heading-xxxl-text-font-weight, var(--spectrum-alias-heading-text-font-weight-regular));
  letter-spacing: var(--spectrum-heading-xxxl-text-letter-spacing, var(--spectrum-global-font-letter-spacing-none));
  line-height: var(--spectrum-heading-xxxl-text-line-height, var(--spectrum-alias-heading-text-line-height));
  text-transform: var(--spectrum-heading-xxxl-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Heading--sizeXXL {
  font-size: var(--spectrum-heading-xxl-text-size, var(--spectrum-alias-heading-xxl-text-size));
  font-style: var(--spectrum-heading-xxl-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-heading-xxl-text-font-weight, var(--spectrum-alias-heading-text-font-weight-regular));
  letter-spacing: var(--spectrum-heading-xxl-text-letter-spacing, var(--spectrum-global-font-letter-spacing-none));
  line-height: var(--spectrum-heading-xxl-text-line-height, var(--spectrum-alias-heading-text-line-height));
  text-transform: var(--spectrum-heading-xxl-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Heading--sizeXL {
  font-size: var(--spectrum-heading-xl-text-size, var(--spectrum-alias-heading-xl-text-size));
  font-style: var(--spectrum-heading-xl-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-heading-xl-text-font-weight, var(--spectrum-alias-heading-text-font-weight-regular));
  letter-spacing: var(--spectrum-heading-xl-text-letter-spacing, var(--spectrum-global-font-letter-spacing-none));
  line-height: var(--spectrum-heading-xl-text-line-height, var(--spectrum-alias-heading-text-line-height));
  text-transform: var(--spectrum-heading-xl-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Heading--sizeL {
  font-size: var(--spectrum-heading-l-text-size, var(--spectrum-alias-heading-l-text-size));
  font-style: var(--spectrum-heading-l-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-heading-l-text-font-weight, var(--spectrum-alias-heading-text-font-weight-regular));
  letter-spacing: var(--spectrum-heading-l-text-letter-spacing, var(--spectrum-global-font-letter-spacing-none));
  line-height: var(--spectrum-heading-l-text-line-height, var(--spectrum-alias-heading-text-line-height));
  text-transform: var(--spectrum-heading-l-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Heading--sizeM {
  font-size: var(--spectrum-heading-m-text-size, var(--spectrum-alias-heading-m-text-size));
  font-style: var(--spectrum-heading-m-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-heading-m-text-font-weight, var(--spectrum-alias-heading-text-font-weight-regular));
  letter-spacing: var(--spectrum-heading-m-text-letter-spacing, var(--spectrum-global-font-letter-spacing-none));
  line-height: var(--spectrum-heading-m-text-line-height, var(--spectrum-alias-heading-text-line-height));
  text-transform: var(--spectrum-heading-m-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Heading--sizeS {
  font-size: var(--spectrum-heading-s-text-size, var(--spectrum-alias-heading-s-text-size));
  font-style: var(--spectrum-heading-s-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-heading-s-text-font-weight, var(--spectrum-alias-heading-text-font-weight-regular));
  letter-spacing: var(--spectrum-heading-s-text-letter-spacing, var(--spectrum-global-font-letter-spacing-none));
  line-height: var(--spectrum-heading-s-text-line-height, var(--spectrum-alias-heading-text-line-height));
  text-transform: var(--spectrum-heading-s-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Heading--sizeXS {
  font-size: var(--spectrum-heading-xs-text-size, var(--spectrum-alias-heading-xs-text-size));
  font-style: var(--spectrum-heading-xs-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-heading-xs-text-font-weight, var(--spectrum-alias-heading-text-font-weight-regular));
  letter-spacing: var(--spectrum-heading-xs-text-letter-spacing, var(--spectrum-global-font-letter-spacing-none));
  line-height: var(--spectrum-heading-xs-text-line-height, var(--spectrum-alias-heading-text-line-height));
  text-transform: var(--spectrum-heading-xs-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Heading--sizeXXS {
  font-size: var(--spectrum-heading-xxs-text-size, var(--spectrum-alias-heading-xxs-text-size));
  font-style: var(--spectrum-heading-xxs-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-heading-xxs-text-font-weight, var(--spectrum-alias-heading-text-font-weight-regular));
  letter-spacing: var(--spectrum-heading-xxs-text-letter-spacing, var(--spectrum-global-font-letter-spacing-none));
  line-height: var(--spectrum-heading-xxs-text-line-height, var(--spectrum-alias-heading-text-line-height));
  text-transform: var(--spectrum-heading-xxs-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Heading {
  font-family: var(--spectrum-heading-m-text-font-family, var(--spectrum-alias-body-text-font-family));
  font-weight: var(--spectrum-heading-m-text-font-weight, var(--spectrum-alias-heading-text-font-weight-regular));
}

.spectrum-Heading .spectrum-Heading-emphasized, .spectrum-Heading em {
  font-style: var(--spectrum-heading-m-emphasized-text-font-style, var(--spectrum-global-font-style-italic));
}

.spectrum-Heading .spectrum-Heading-strong, .spectrum-Heading strong {
  font-weight: var(--spectrum-heading-m-strong-text-font-weight, var(--spectrum-alias-heading-text-font-weight-regular-strong));
}

.spectrum-Heading--serif {
  font-family: var(--spectrum-body-m-serif-text-font-family, var(--spectrum-alias-serif-text-font-family));
}

.spectrum-Heading--heavy {
  font-weight: var(--spectrum-heading-m-heavy-text-font-weight, var(--spectrum-alias-heading-text-font-weight-heavy));
}

.spectrum-Heading--heavy .spectrum-Heading-emphasized, .spectrum-Heading--heavy em {
  font-style: var(--spectrum-heading-m-heavy-emphasized-text-font-style, var(--spectrum-global-font-style-italic));
}

.spectrum-Heading--heavy .spectrum-Heading-strong, .spectrum-Heading--heavy strong {
  font-weight: var(--spectrum-heading-m-heavy-strong-text-font-weight, var(--spectrum-alias-heading-text-font-weight-heavy-strong));
}

.spectrum-Heading--light {
  font-weight: var(--spectrum-heading-m-light-emphasized-text-font-weight, var(--spectrum-alias-heading-text-font-weight-light));
}

.spectrum-Heading--light .spectrum-Heading-emphasized, .spectrum-Heading--light em {
  font-style: var(--spectrum-heading-m-light-emphasized-text-font-style, var(--spectrum-global-font-style-italic));
}

.spectrum-Heading--light .spectrum-Heading-strong, .spectrum-Heading--light strong {
  font-weight: var(--spectrum-heading-m-light-strong-text-font-weight, var(--spectrum-alias-heading-text-font-weight-light-strong));
}

.spectrum-Body--sizeXXXL {
  font-size: var(--spectrum-body-xxxl-text-size, var(--spectrum-global-dimension-font-size-600));
  font-style: var(--spectrum-body-xxxl-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-body-xxxl-text-font-weight, var(--spectrum-alias-body-text-font-weight));
  letter-spacing: var(--spectrum-body-xxxl-text-letter-spacing, var(--spectrum-global-font-letter-spacing-none));
  line-height: var(--spectrum-body-xxxl-text-line-height, var(--spectrum-alias-body-text-line-height));
  text-transform: var(--spectrum-body-xxxl-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Body--sizeXXL {
  font-size: var(--spectrum-body-xxl-text-size, var(--spectrum-global-dimension-font-size-500));
  font-style: var(--spectrum-body-xxl-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-body-xxl-text-font-weight, var(--spectrum-alias-body-text-font-weight));
  letter-spacing: var(--spectrum-body-xxl-text-letter-spacing, var(--spectrum-global-font-letter-spacing-none));
  line-height: var(--spectrum-body-xxl-text-line-height, var(--spectrum-alias-body-text-line-height));
  text-transform: var(--spectrum-body-xxl-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Body--sizeXL {
  font-size: var(--spectrum-body-xl-text-size, var(--spectrum-global-dimension-font-size-400));
  font-style: var(--spectrum-body-xl-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-body-xl-text-font-weight, var(--spectrum-alias-body-text-font-weight));
  letter-spacing: var(--spectrum-body-xl-text-letter-spacing, var(--spectrum-global-font-letter-spacing-none));
  line-height: var(--spectrum-body-xl-text-line-height, var(--spectrum-alias-body-text-line-height));
  text-transform: var(--spectrum-body-xl-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Body--sizeL {
  font-size: var(--spectrum-body-l-text-size, var(--spectrum-global-dimension-font-size-300));
  font-style: var(--spectrum-body-l-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-body-l-text-font-weight, var(--spectrum-alias-body-text-font-weight));
  letter-spacing: var(--spectrum-body-l-text-letter-spacing, var(--spectrum-global-font-letter-spacing-none));
  line-height: var(--spectrum-body-l-text-line-height, var(--spectrum-alias-body-text-line-height));
  text-transform: var(--spectrum-body-l-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Body--sizeM {
  font-size: var(--spectrum-body-m-text-size, var(--spectrum-global-dimension-font-size-200));
  font-style: var(--spectrum-body-m-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-body-m-text-font-weight, var(--spectrum-alias-body-text-font-weight));
  letter-spacing: var(--spectrum-body-m-text-letter-spacing, var(--spectrum-global-font-letter-spacing-none));
  line-height: var(--spectrum-body-m-text-line-height, var(--spectrum-alias-body-text-line-height));
  text-transform: var(--spectrum-body-m-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Body--sizeS {
  font-size: var(--spectrum-body-s-text-size, var(--spectrum-global-dimension-font-size-100));
  font-style: var(--spectrum-body-s-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-body-s-text-font-weight, var(--spectrum-alias-body-text-font-weight));
  letter-spacing: var(--spectrum-body-s-text-letter-spacing, var(--spectrum-global-font-letter-spacing-none));
  line-height: var(--spectrum-body-s-text-line-height, var(--spectrum-alias-body-text-line-height));
  text-transform: var(--spectrum-body-s-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Body--sizeXS {
  font-size: var(--spectrum-body-xs-text-size, var(--spectrum-global-dimension-font-size-75));
  font-style: var(--spectrum-body-xs-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-body-xs-text-font-weight, var(--spectrum-alias-body-text-font-weight));
  letter-spacing: var(--spectrum-body-xs-text-letter-spacing, var(--spectrum-global-font-letter-spacing-none));
  line-height: var(--spectrum-body-xs-text-line-height, var(--spectrum-alias-body-text-line-height));
  text-transform: var(--spectrum-body-xs-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Body {
  font-family: var(--spectrum-body-m-text-font-family, var(--spectrum-alias-body-text-font-family));
}

.spectrum-Body .spectrum-Body-strong, .spectrum-Body strong {
  font-weight: var(--spectrum-body-m-strong-text-font-weight, var(--spectrum-global-font-weight-bold));
}

.spectrum-Body .spectrum-Body-emphasized, .spectrum-Body em {
  font-style: var(--spectrum-body-m-emphasized-text-font-style, var(--spectrum-global-font-style-italic));
}

.spectrum-Body--serif {
  font-family: var(--spectrum-body-m-serif-text-font-family, var(--spectrum-alias-serif-text-font-family));
}

.spectrum-Detail {
  font-family: var(--spectrum-heading-m-text-font-family, var(--spectrum-alias-body-text-font-family));
}

.spectrum-Detail .spectrum-Detail-strong, .spectrum-Detail strong {
  font-weight: var(--spectrum-detail-m-strong-text-font-weight, var(--spectrum-alias-detail-text-font-weight-regular));
}

.spectrum-Detail .spectrum-Detail-emphasized, .spectrum-Detail em {
  font-style: var(--spectrum-detail-m-emphasized-text-font-style, var(--spectrum-global-font-style-italic));
}

.spectrum-Detail--light {
  font-style: var(--spectrum-detail-m-light-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-detail-m-light-text-font-weight, var(--spectrum-alias-detail-text-font-weight-light));
}

.spectrum-Detail--serif {
  font-family: var(--spectrum-body-m-serif-text-font-family, var(--spectrum-alias-serif-text-font-family));
}

.spectrum-Detail--sizeXL {
  font-size: var(--spectrum-detail-xl-text-size, var(--spectrum-global-dimension-font-size-200));
  font-style: var(--spectrum-detail-xl-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-detail-xl-text-font-weight, var(--spectrum-alias-detail-text-font-weight-regular));
  letter-spacing: var(--spectrum-detail-xl-text-letter-spacing, var(--spectrum-global-font-letter-spacing-medium));
  line-height: var(--spectrum-detail-xl-text-line-height, var(--spectrum-alias-heading-text-line-height));
  text-transform: var(--spectrum-detail-xl-text-transform, uppercase);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Detail--sizeXL em {
  font-size: var(--spectrum-detail-xl-emphasized-text-size, var(--spectrum-global-dimension-font-size-200));
  font-style: var(--spectrum-detail-xl-emphasized-text-font-style, var(--spectrum-global-font-style-italic));
  font-weight: var(--spectrum-detail-xl-emphasized-text-font-weight, var(--spectrum-alias-detail-text-font-weight-regular));
  letter-spacing: var(--spectrum-detail-xl-emphasized-text-letter-spacing, var(--spectrum-global-font-letter-spacing-medium));
  line-height: var(--spectrum-detail-xl-emphasized-text-line-height, var(--spectrum-alias-heading-text-line-height));
  text-transform: var(--spectrum-detail-xl-emphasized-text-transform, uppercase);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Detail--sizeXL strong {
  font-size: var(--spectrum-detail-xl-strong-text-size, var(--spectrum-global-dimension-font-size-200));
  font-style: var(--spectrum-detail-xl-strong-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-detail-xl-strong-text-font-weight, var(--spectrum-alias-detail-text-font-weight-regular));
  letter-spacing: var(--spectrum-detail-xl-strong-text-letter-spacing, var(--spectrum-global-font-letter-spacing-medium));
  line-height: var(--spectrum-detail-xl-strong-text-line-height, var(--spectrum-alias-heading-text-line-height));
  text-transform: var(--spectrum-detail-xl-strong-text-transform, uppercase);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Detail--sizeL {
  font-size: var(--spectrum-detail-l-text-size, var(--spectrum-global-dimension-font-size-100));
  font-style: var(--spectrum-detail-l-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-detail-l-text-font-weight, var(--spectrum-alias-detail-text-font-weight-regular));
  letter-spacing: var(--spectrum-detail-l-text-letter-spacing, var(--spectrum-global-font-letter-spacing-medium));
  line-height: var(--spectrum-detail-l-text-line-height, var(--spectrum-alias-heading-text-line-height));
  text-transform: var(--spectrum-detail-l-text-transform, uppercase);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Detail--sizeL em {
  font-size: var(--spectrum-detail-l-emphasized-text-size, var(--spectrum-global-dimension-font-size-100));
  font-style: var(--spectrum-detail-l-emphasized-text-font-style, var(--spectrum-global-font-style-italic));
  font-weight: var(--spectrum-detail-l-emphasized-text-font-weight, var(--spectrum-alias-detail-text-font-weight-regular));
  letter-spacing: var(--spectrum-detail-l-emphasized-text-letter-spacing, var(--spectrum-global-font-letter-spacing-medium));
  line-height: var(--spectrum-detail-l-emphasized-text-line-height, var(--spectrum-alias-heading-text-line-height));
  text-transform: var(--spectrum-detail-l-emphasized-text-transform, uppercase);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Detail--sizeL strong {
  font-size: var(--spectrum-detail-l-strong-text-size, var(--spectrum-global-dimension-font-size-100));
  font-style: var(--spectrum-detail-l-strong-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-detail-l-strong-text-font-weight, var(--spectrum-alias-detail-text-font-weight-regular));
  letter-spacing: var(--spectrum-detail-l-strong-text-letter-spacing, var(--spectrum-global-font-letter-spacing-medium));
  line-height: var(--spectrum-detail-l-strong-text-line-height, var(--spectrum-alias-heading-text-line-height));
  text-transform: var(--spectrum-detail-l-strong-text-transform, uppercase);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Detail--sizeM {
  font-size: var(--spectrum-detail-m-text-size, var(--spectrum-global-dimension-font-size-75));
  font-style: var(--spectrum-detail-m-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-detail-m-text-font-weight, var(--spectrum-alias-detail-text-font-weight-regular));
  letter-spacing: var(--spectrum-detail-m-text-letter-spacing, var(--spectrum-global-font-letter-spacing-medium));
  line-height: var(--spectrum-detail-m-text-line-height, var(--spectrum-alias-heading-text-line-height));
  text-transform: var(--spectrum-detail-m-text-transform, uppercase);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Detail--sizeM em {
  font-size: var(--spectrum-detail-m-emphasized-text-size, var(--spectrum-global-dimension-font-size-75));
  font-style: var(--spectrum-detail-m-emphasized-text-font-style, var(--spectrum-global-font-style-italic));
  font-weight: var(--spectrum-detail-m-emphasized-text-font-weight, var(--spectrum-alias-detail-text-font-weight-regular));
  letter-spacing: var(--spectrum-detail-m-emphasized-text-letter-spacing, var(--spectrum-global-font-letter-spacing-medium));
  line-height: var(--spectrum-detail-m-emphasized-text-line-height, var(--spectrum-alias-heading-text-line-height));
  text-transform: var(--spectrum-detail-m-emphasized-text-transform, uppercase);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Detail--sizeM strong {
  font-size: var(--spectrum-detail-m-strong-text-size, var(--spectrum-global-dimension-font-size-75));
  font-style: var(--spectrum-detail-m-strong-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-detail-m-strong-text-font-weight, var(--spectrum-alias-detail-text-font-weight-regular));
  letter-spacing: var(--spectrum-detail-m-strong-text-letter-spacing, var(--spectrum-global-font-letter-spacing-medium));
  line-height: var(--spectrum-detail-m-strong-text-line-height, var(--spectrum-alias-heading-text-line-height));
  text-transform: var(--spectrum-detail-m-strong-text-transform, uppercase);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Detail--sizeS {
  font-size: var(--spectrum-detail-s-text-size, var(--spectrum-global-dimension-font-size-50));
  font-style: var(--spectrum-detail-s-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-detail-s-text-font-weight, var(--spectrum-alias-detail-text-font-weight-regular));
  letter-spacing: var(--spectrum-detail-s-text-letter-spacing, var(--spectrum-global-font-letter-spacing-medium));
  line-height: var(--spectrum-detail-s-text-line-height, var(--spectrum-alias-heading-text-line-height));
  text-transform: var(--spectrum-detail-s-text-transform, uppercase);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Detail--sizeS em {
  font-size: var(--spectrum-detail-s-emphasized-text-size, var(--spectrum-global-dimension-font-size-50));
  font-style: var(--spectrum-detail-s-emphasized-text-font-style, var(--spectrum-global-font-style-italic));
  font-weight: var(--spectrum-detail-s-emphasized-text-font-weight, var(--spectrum-alias-detail-text-font-weight-regular));
  letter-spacing: var(--spectrum-detail-s-emphasized-text-letter-spacing, var(--spectrum-global-font-letter-spacing-medium));
  line-height: var(--spectrum-detail-s-emphasized-text-line-height, var(--spectrum-alias-heading-text-line-height));
  text-transform: var(--spectrum-detail-s-emphasized-text-transform, uppercase);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Detail--sizeS strong {
  font-size: var(--spectrum-detail-s-strong-text-size, var(--spectrum-global-dimension-font-size-50));
  font-style: var(--spectrum-detail-s-strong-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-detail-s-strong-text-font-weight, var(--spectrum-alias-detail-text-font-weight-regular));
  letter-spacing: var(--spectrum-detail-s-strong-text-letter-spacing, var(--spectrum-global-font-letter-spacing-medium));
  line-height: var(--spectrum-detail-s-strong-text-line-height, var(--spectrum-alias-heading-text-line-height));
  text-transform: var(--spectrum-detail-s-strong-text-transform, uppercase);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Code {
  font-family: var(--spectrum-heading-m-text-font-family, var(--spectrum-alias-body-text-font-family));
}

.spectrum-Code .spectrum-Code-strong, .spectrum-Code strong {
  font-weight: var(--spectrum-code-m-strong-text-font-weight, var(--spectrum-global-font-weight-bold));
}

.spectrum-Code .spectrum-Code-emphasized, .spectrum-Code em {
  font-style: var(--spectrum-code-m-emphasized-text-font-style, var(--spectrum-global-font-style-italic));
}

.spectrum-Code--serif {
  font-family: var(--spectrum-body-m-serif-text-font-family, var(--spectrum-alias-serif-text-font-family));
}

.spectrum-Code--sizeXL {
  font-family: var(--spectrum-code-xl-text-font-family, var(--spectrum-alias-code-text-font-family));
  font-size: var(--spectrum-code-xl-text-size, var(--spectrum-global-dimension-font-size-400));
  font-style: var(--spectrum-code-xl-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-code-xl-text-font-weight, var(--spectrum-alias-code-text-font-weight-regular));
  letter-spacing: var(--spectrum-code-xl-text-letter-spacing, var(--spectrum-global-font-letter-spacing-none));
  line-height: var(--spectrum-code-xl-text-line-height, var(--spectrum-alias-body-text-line-height));
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Code--sizeL {
  font-family: var(--spectrum-code-l-text-font-family, var(--spectrum-alias-code-text-font-family));
  font-size: var(--spectrum-code-l-text-size, var(--spectrum-global-dimension-font-size-300));
  font-style: var(--spectrum-code-l-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-code-l-text-font-weight, var(--spectrum-alias-code-text-font-weight-regular));
  letter-spacing: var(--spectrum-code-l-text-letter-spacing, var(--spectrum-global-font-letter-spacing-none));
  line-height: var(--spectrum-code-l-text-line-height, var(--spectrum-alias-body-text-line-height));
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Code--sizeM {
  font-family: var(--spectrum-code-m-text-font-family, var(--spectrum-alias-code-text-font-family));
  font-size: var(--spectrum-code-m-text-size, var(--spectrum-global-dimension-font-size-200));
  font-style: var(--spectrum-code-m-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-code-m-text-font-weight, var(--spectrum-alias-code-text-font-weight-regular));
  letter-spacing: var(--spectrum-code-m-text-letter-spacing, var(--spectrum-global-font-letter-spacing-none));
  line-height: var(--spectrum-code-m-text-line-height, var(--spectrum-alias-body-text-line-height));
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Code--sizeS {
  font-family: var(--spectrum-code-s-text-font-family, var(--spectrum-alias-code-text-font-family));
  font-size: var(--spectrum-code-s-text-size, var(--spectrum-global-dimension-font-size-100));
  font-style: var(--spectrum-code-s-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-code-s-text-font-weight, var(--spectrum-alias-code-text-font-weight-regular));
  letter-spacing: var(--spectrum-code-s-text-letter-spacing, var(--spectrum-global-font-letter-spacing-none));
  line-height: var(--spectrum-code-s-text-line-height, var(--spectrum-alias-body-text-line-height));
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Code--sizeXS {
  font-family: var(--spectrum-code-xs-text-font-family, var(--spectrum-alias-code-text-font-family));
  font-size: var(--spectrum-code-xs-text-size, var(--spectrum-global-dimension-font-size-75));
  font-style: var(--spectrum-code-xs-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-code-xs-text-font-weight, var(--spectrum-alias-code-text-font-weight-regular));
  letter-spacing: var(--spectrum-code-xs-text-letter-spacing, var(--spectrum-global-font-letter-spacing-none));
  line-height: var(--spectrum-code-xs-text-line-height, var(--spectrum-alias-body-text-line-height));
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Typography .spectrum-Heading--sizeXXXL {
  margin-bottom: var(--spectrum-heading-xxxl-margin-bottom, var(--spectrum-global-dimension-size-125));
  margin-top: var(--spectrum-heading-xxxl-margin-top, var(--spectrum-alias-heading-xxxl-margin-top));
}

.spectrum-Typography .spectrum-Heading--sizeXXL {
  margin-bottom: var(--spectrum-heading-xxl-margin-bottom, var(--spectrum-global-dimension-size-115));
  margin-top: var(--spectrum-heading-xxl-margin-top, var(--spectrum-alias-heading-xxl-margin-top));
}

.spectrum-Typography .spectrum-Heading--sizeXL {
  margin-bottom: var(--spectrum-heading-xl-margin-bottom, var(--spectrum-global-dimension-size-100));
  margin-top: var(--spectrum-heading-xl-margin-top, var(--spectrum-alias-heading-xl-margin-top));
}

.spectrum-Typography .spectrum-Heading--sizeL {
  margin-bottom: var(--spectrum-heading-l-margin-bottom, var(--spectrum-global-dimension-size-85));
  margin-top: var(--spectrum-heading-l-margin-top, var(--spectrum-alias-heading-l-margin-top));
}

.spectrum-Typography .spectrum-Heading--sizeM {
  margin-bottom: var(--spectrum-heading-m-margin-bottom, var(--spectrum-global-dimension-size-75));
  margin-top: var(--spectrum-heading-m-margin-top, var(--spectrum-alias-heading-m-margin-top));
}

.spectrum-Typography .spectrum-Heading--sizeS {
  margin-bottom: var(--spectrum-heading-s-margin-bottom, var(--spectrum-global-dimension-size-65));
  margin-top: var(--spectrum-heading-s-margin-top, var(--spectrum-alias-heading-s-margin-top));
}

.spectrum-Typography .spectrum-Heading--sizeXS {
  margin-bottom: var(--spectrum-heading-xs-margin-bottom, var(--spectrum-global-dimension-size-50));
  margin-top: var(--spectrum-heading-xs-margin-top, var(--spectrum-alias-heading-xs-margin-top));
}

.spectrum-Typography .spectrum-Heading--sizeXXS {
  margin-bottom: var(--spectrum-heading-xxs-margin-bottom, var(--spectrum-global-dimension-size-40));
  margin-top: var(--spectrum-heading-xxs-margin-top, var(--spectrum-alias-heading-xxs-margin-top));
}

.spectrum-Typography .spectrum-Body--sizeXXXL {
  margin-bottom: var(--spectrum-body-xxxl-margin-bottom, var(--spectrum-global-dimension-size-400));
  margin-top: var(--spectrum-body-xxxl-margin-top, 0);
}

.spectrum-Typography .spectrum-Body--sizeXXL {
  margin-bottom: var(--spectrum-body-xxl-margin-bottom, var(--spectrum-global-dimension-size-300));
  margin-top: var(--spectrum-body-xxl-margin-top, 0);
}

.spectrum-Typography .spectrum-Body--sizeXL {
  margin-bottom: var(--spectrum-body-xl-margin-bottom, var(--spectrum-global-dimension-size-200));
  margin-top: var(--spectrum-body-xl-margin-top, 0);
}

.spectrum-Typography .spectrum-Body--sizeL {
  margin-bottom: var(--spectrum-body-l-margin-bottom, var(--spectrum-global-dimension-size-160));
  margin-top: var(--spectrum-body-l-margin-top, 0);
}

.spectrum-Typography .spectrum-Body--sizeM {
  margin-bottom: var(--spectrum-body-m-margin-bottom, var(--spectrum-global-dimension-size-150));
  margin-top: var(--spectrum-body-m-margin-top, 0);
}

.spectrum-Typography .spectrum-Body--sizeS {
  margin-bottom: var(--spectrum-body-s-margin-bottom, var(--spectrum-global-dimension-size-125));
  margin-top: var(--spectrum-body-s-margin-top, 0);
}

.spectrum-Typography .spectrum-Body--sizeXS {
  margin-bottom: var(--spectrum-body-xs-margin-bottom, var(--spectrum-global-dimension-size-115));
  margin-top: var(--spectrum-body-xs-margin-top, 0);
}

.spectrum:lang(ja) .spectrum-Heading--sizeXXXL, .spectrum:lang(ko) .spectrum-Heading--sizeXXXL, .spectrum:lang(zh) .spectrum-Heading--sizeXXXL {
  font-size: var(--spectrum-heading-han-xxxl-text-size, var(--spectrum-alias-heading-han-xxxl-text-size));
  font-style: var(--spectrum-heading-han-xxxl-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-heading-han-xxxl-text-font-weight, var(--spectrum-alias-han-heading-text-font-weight-regular));
  letter-spacing: var(--spectrum-heading-han-xxxl-text-letter-spacing, var(--spectrum-global-font-letter-spacing-han));
  line-height: var(--spectrum-heading-han-xxxl-text-line-height, var(--spectrum-alias-han-heading-text-line-height));
  text-transform: var(--spectrum-heading-han-xxxl-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Heading--sizeXXL, .spectrum:lang(ko) .spectrum-Heading--sizeXXL, .spectrum:lang(zh) .spectrum-Heading--sizeXXL {
  font-size: var(--spectrum-heading-han-xxl-text-size, var(--spectrum-alias-heading-han-xxl-text-size));
  font-style: var(--spectrum-heading-han-xxl-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-heading-han-xxl-text-font-weight, var(--spectrum-alias-han-heading-text-font-weight-regular));
  letter-spacing: var(--spectrum-heading-han-xxl-text-letter-spacing, var(--spectrum-global-font-letter-spacing-han));
  line-height: var(--spectrum-heading-han-xxl-text-line-height, var(--spectrum-alias-han-heading-text-line-height));
  text-transform: var(--spectrum-heading-han-xxl-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Heading--sizeXL, .spectrum:lang(ko) .spectrum-Heading--sizeXL, .spectrum:lang(zh) .spectrum-Heading--sizeXL {
  font-size: var(--spectrum-heading-han-xl-text-size, var(--spectrum-alias-heading-han-xl-text-size));
  font-style: var(--spectrum-heading-han-xl-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-heading-han-xl-text-font-weight, var(--spectrum-alias-han-heading-text-font-weight-regular));
  letter-spacing: var(--spectrum-heading-han-xl-text-letter-spacing, var(--spectrum-global-font-letter-spacing-han));
  line-height: var(--spectrum-heading-han-xl-text-line-height, var(--spectrum-alias-han-heading-text-line-height));
  text-transform: var(--spectrum-heading-han-xl-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Heading--sizeL, .spectrum:lang(ko) .spectrum-Heading--sizeL, .spectrum:lang(zh) .spectrum-Heading--sizeL {
  font-size: var(--spectrum-heading-han-l-text-size, var(--spectrum-alias-heading-han-l-text-size));
  font-style: var(--spectrum-heading-han-l-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-heading-han-l-text-font-weight, var(--spectrum-alias-han-heading-text-font-weight-regular));
  letter-spacing: var(--spectrum-heading-han-l-text-letter-spacing, var(--spectrum-global-font-letter-spacing-han));
  line-height: var(--spectrum-heading-han-l-text-line-height, var(--spectrum-alias-han-heading-text-line-height));
  text-transform: var(--spectrum-heading-han-l-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Heading--sizeM, .spectrum:lang(ko) .spectrum-Heading--sizeM, .spectrum:lang(zh) .spectrum-Heading--sizeM {
  font-size: var(--spectrum-heading-han-m-text-size, var(--spectrum-alias-heading-han-m-text-size));
  font-style: var(--spectrum-heading-han-m-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-heading-han-m-text-font-weight, var(--spectrum-alias-han-heading-text-font-weight-regular));
  letter-spacing: var(--spectrum-heading-han-m-text-letter-spacing, var(--spectrum-global-font-letter-spacing-han));
  line-height: var(--spectrum-heading-han-m-text-line-height, var(--spectrum-alias-han-heading-text-line-height));
  text-transform: var(--spectrum-heading-han-m-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Heading--sizeS, .spectrum:lang(ko) .spectrum-Heading--sizeS, .spectrum:lang(zh) .spectrum-Heading--sizeS {
  font-size: var(--spectrum-heading-han-s-text-size, var(--spectrum-alias-heading-han-s-text-size));
  font-style: var(--spectrum-heading-han-s-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-heading-han-s-text-font-weight, var(--spectrum-alias-han-heading-text-font-weight-regular));
  letter-spacing: var(--spectrum-heading-han-s-text-letter-spacing, var(--spectrum-global-font-letter-spacing-han));
  line-height: var(--spectrum-heading-han-s-text-line-height, var(--spectrum-alias-han-heading-text-line-height));
  text-transform: var(--spectrum-heading-han-s-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Heading--sizeXS, .spectrum:lang(ko) .spectrum-Heading--sizeXS, .spectrum:lang(zh) .spectrum-Heading--sizeXS {
  font-size: var(--spectrum-heading-han-xs-text-size, var(--spectrum-alias-heading-han-xs-text-size));
  font-style: var(--spectrum-heading-han-xs-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-heading-han-xs-text-font-weight, var(--spectrum-alias-han-heading-text-font-weight-regular));
  letter-spacing: var(--spectrum-heading-han-xs-text-letter-spacing, var(--spectrum-global-font-letter-spacing-han));
  line-height: var(--spectrum-heading-han-xs-text-line-height, var(--spectrum-alias-han-heading-text-line-height));
  text-transform: var(--spectrum-heading-han-xs-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Heading--sizeXXS, .spectrum:lang(ko) .spectrum-Heading--sizeXXS, .spectrum:lang(zh) .spectrum-Heading--sizeXXS {
  font-size: var(--spectrum-heading-han-xxs-text-size, var(--spectrum-alias-heading-han-xxs-text-size));
  font-style: var(--spectrum-heading-han-xxs-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-heading-han-xxs-text-font-weight, var(--spectrum-alias-han-heading-text-font-weight-regular));
  letter-spacing: var(--spectrum-heading-han-xxs-text-letter-spacing, var(--spectrum-global-font-letter-spacing-han));
  line-height: var(--spectrum-heading-han-xxs-text-line-height, var(--spectrum-alias-han-heading-text-line-height));
  text-transform: var(--spectrum-heading-han-xxs-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Heading--heavy, .spectrum:lang(ko) .spectrum-Heading--heavy, .spectrum:lang(zh) .spectrum-Heading--heavy {
  font-weight: var(--spectrum-heading-han-m-text-font-weight, var(--spectrum-alias-han-heading-text-font-weight-regular));
}

.spectrum:lang(ja) .spectrum-Heading--heavy .spectrum-Heading--emphasized, .spectrum:lang(ja) .spectrum-Heading--heavy em, .spectrum:lang(ko) .spectrum-Heading--heavy .spectrum-Heading--emphasized, .spectrum:lang(ko) .spectrum-Heading--heavy em, .spectrum:lang(zh) .spectrum-Heading--heavy .spectrum-Heading--emphasized, .spectrum:lang(zh) .spectrum-Heading--heavy em {
  font-style: var(--spectrum-heading-han-heavy-m-emphasized-text-font-style);
  font-weight: var(--spectrum-heading-han-heavy-m-emphasized-text-font-weight);
}

.spectrum:lang(ja) .spectrum-Heading--heavy .spectrum-Heading--strong, .spectrum:lang(ja) .spectrum-Heading--heavy strong, .spectrum:lang(ko) .spectrum-Heading--heavy .spectrum-Heading--strong, .spectrum:lang(ko) .spectrum-Heading--heavy strong, .spectrum:lang(zh) .spectrum-Heading--heavy .spectrum-Heading--strong, .spectrum:lang(zh) .spectrum-Heading--heavy strong {
  font-style: var(--spectrum-heading-m-heavy-strong-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-heading-m-heavy-strong-text-font-weight, var(--spectrum-alias-heading-text-font-weight-heavy-strong));
}

.spectrum:lang(ja) .spectrum-Heading--light, .spectrum:lang(ko) .spectrum-Heading--light, .spectrum:lang(zh) .spectrum-Heading--light {
  font-weight: var(--spectrum-heading-han-m-text-font-weight, var(--spectrum-alias-han-heading-text-font-weight-regular));
}

.spectrum:lang(ja) .spectrum-Heading--light .spectrum-Heading--emphasized, .spectrum:lang(ja) .spectrum-Heading--light em, .spectrum:lang(ko) .spectrum-Heading--light .spectrum-Heading--emphasized, .spectrum:lang(ko) .spectrum-Heading--light em, .spectrum:lang(zh) .spectrum-Heading--light .spectrum-Heading--emphasized, .spectrum:lang(zh) .spectrum-Heading--light em {
  font-style: var(--spectrum-heading-han-m-light-emphasized-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-heading-han-m-light-emphasized-text-font-weight, var(--spectrum-alias-han-heading-text-font-weight-light-emphasis));
}

.spectrum:lang(ja) .spectrum-Heading--light .spectrum-Heading--strong, .spectrum:lang(ja) .spectrum-Heading--light strong, .spectrum:lang(ko) .spectrum-Heading--light .spectrum-Heading--strong, .spectrum:lang(ko) .spectrum-Heading--light strong, .spectrum:lang(zh) .spectrum-Heading--light .spectrum-Heading--strong, .spectrum:lang(zh) .spectrum-Heading--light strong {
  font-style: var(--spectrum-heading-han-m-light-strong-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-heading-han-m-light-strong-text-font-weight, var(--spectrum-alias-han-heading-text-font-weight-light-strong));
}

.spectrum:lang(ja) .spectrum-Body--sizeXXXL, .spectrum:lang(ko) .spectrum-Body--sizeXXXL, .spectrum:lang(zh) .spectrum-Body--sizeXXXL {
  font-size: var(--spectrum-body-han-xxxl-text-size, var(--spectrum-global-dimension-font-size-600));
  font-style: var(--spectrum-body-han-xxxl-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-body-han-xxxl-text-font-weight, var(--spectrum-alias-han-body-text-font-weight-regular));
  letter-spacing: var(--spectrum-body-han-xxxl-text-letter-spacing, var(--spectrum-global-font-letter-spacing-han));
  line-height: var(--spectrum-body-han-xxxl-text-line-height, var(--spectrum-alias-han-body-text-line-height));
  text-transform: var(--spectrum-body-han-xxxl-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Body--sizeXXL, .spectrum:lang(ko) .spectrum-Body--sizeXXL, .spectrum:lang(zh) .spectrum-Body--sizeXXL {
  font-size: var(--spectrum-body-han-xxl-text-size, var(--spectrum-global-dimension-font-size-500));
  font-style: var(--spectrum-body-han-xxl-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-body-han-xxl-text-font-weight, var(--spectrum-alias-han-body-text-font-weight-regular));
  letter-spacing: var(--spectrum-body-han-xxl-text-letter-spacing, var(--spectrum-global-font-letter-spacing-han));
  line-height: var(--spectrum-body-han-xxl-text-line-height, var(--spectrum-alias-han-body-text-line-height));
  text-transform: var(--spectrum-body-han-xxl-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Body--sizeXL, .spectrum:lang(ko) .spectrum-Body--sizeXL, .spectrum:lang(zh) .spectrum-Body--sizeXL {
  font-size: var(--spectrum-body-han-xl-text-size, var(--spectrum-global-dimension-font-size-400));
  font-style: var(--spectrum-body-han-xl-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-body-han-xl-text-font-weight, var(--spectrum-alias-han-body-text-font-weight-regular));
  letter-spacing: var(--spectrum-body-han-xl-text-letter-spacing, var(--spectrum-global-font-letter-spacing-han));
  line-height: var(--spectrum-body-han-xl-text-line-height, var(--spectrum-alias-han-body-text-line-height));
  text-transform: var(--spectrum-body-han-xl-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Body--sizeL, .spectrum:lang(ko) .spectrum-Body--sizeL, .spectrum:lang(zh) .spectrum-Body--sizeL {
  font-size: var(--spectrum-body-han-l-text-size, var(--spectrum-global-dimension-font-size-300));
  font-style: var(--spectrum-body-han-l-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-body-han-l-text-font-weight, var(--spectrum-alias-han-body-text-font-weight-regular));
  letter-spacing: var(--spectrum-body-han-l-text-letter-spacing, var(--spectrum-global-font-letter-spacing-han));
  line-height: var(--spectrum-body-han-l-text-line-height, var(--spectrum-alias-han-body-text-line-height));
  text-transform: var(--spectrum-body-han-l-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Body--sizeM, .spectrum:lang(ko) .spectrum-Body--sizeM, .spectrum:lang(zh) .spectrum-Body--sizeM {
  font-size: var(--spectrum-body-han-m-text-size, var(--spectrum-global-dimension-font-size-200));
  font-style: var(--spectrum-body-han-m-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-body-han-m-text-font-weight, var(--spectrum-alias-han-body-text-font-weight-regular));
  letter-spacing: var(--spectrum-body-han-m-text-letter-spacing, var(--spectrum-global-font-letter-spacing-han));
  line-height: var(--spectrum-body-han-m-text-line-height, var(--spectrum-alias-han-body-text-line-height));
  text-transform: var(--spectrum-body-han-m-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Body--sizeS, .spectrum:lang(ko) .spectrum-Body--sizeS, .spectrum:lang(zh) .spectrum-Body--sizeS {
  font-size: var(--spectrum-body-han-s-text-size, var(--spectrum-global-dimension-font-size-100));
  font-style: var(--spectrum-body-han-s-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-body-han-s-text-font-weight, var(--spectrum-alias-han-body-text-font-weight-regular));
  letter-spacing: var(--spectrum-body-han-s-text-letter-spacing, var(--spectrum-global-font-letter-spacing-han));
  line-height: var(--spectrum-body-han-s-text-line-height, var(--spectrum-alias-han-body-text-line-height));
  text-transform: var(--spectrum-body-han-s-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Body--sizeXS, .spectrum:lang(ko) .spectrum-Body--sizeXS, .spectrum:lang(zh) .spectrum-Body--sizeXS {
  font-size: var(--spectrum-body-han-xs-text-size, var(--spectrum-global-dimension-font-size-75));
  font-style: var(--spectrum-body-han-xs-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-body-han-xs-text-font-weight, var(--spectrum-alias-han-body-text-font-weight-regular));
  letter-spacing: var(--spectrum-body-han-xs-text-letter-spacing, var(--spectrum-global-font-letter-spacing-han));
  line-height: var(--spectrum-body-han-xs-text-line-height, var(--spectrum-alias-han-body-text-line-height));
  text-transform: var(--spectrum-body-han-xs-text-transform, none);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Detail--sizeXL, .spectrum:lang(ko) .spectrum-Detail--sizeXL, .spectrum:lang(zh) .spectrum-Detail--sizeXL {
  font-size: var(--spectrum-detail-han-xl-text-size, var(--spectrum-global-dimension-font-size-200));
  font-style: var(--spectrum-detail-han-xl-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-detail-han-xl-text-font-weight, var(--spectrum-alias-han-heading-text-font-weight-regular));
  letter-spacing: var(--spectrum-detail-han-xl-text-letter-spacing, var(--spectrum-global-font-letter-spacing-medium));
  line-height: var(--spectrum-detail-han-xl-text-line-height, var(--spectrum-alias-han-heading-text-line-height));
  text-transform: var(--spectrum-detail-han-xl-text-transform, uppercase);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Detail--sizeXL em, .spectrum:lang(ko) .spectrum-Detail--sizeXL em, .spectrum:lang(zh) .spectrum-Detail--sizeXL em {
  font-size: var(--spectrum-detail-han-xl-emphasized-text-size, var(--spectrum-global-dimension-font-size-200));
  font-style: var(--spectrum-detail-han-xl-emphasized-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-detail-han-xl-emphasized-text-font-weight, var(--spectrum-alias-han-heading-text-font-weight-regular-emphasis));
  letter-spacing: var(--spectrum-detail-han-xl-emphasized-text-letter-spacing, var(--spectrum-global-font-letter-spacing-medium));
  line-height: var(--spectrum-detail-han-xl-emphasized-text-line-height, var(--spectrum-alias-han-heading-text-line-height));
  text-transform: var(--spectrum-detail-han-xl-emphasized-text-transform, uppercase);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Detail--sizeXL strong, .spectrum:lang(ko) .spectrum-Detail--sizeXL strong, .spectrum:lang(zh) .spectrum-Detail--sizeXL strong {
  font-size: var(--spectrum-detail-han-xl-strong-text-size, var(--spectrum-global-dimension-font-size-200));
  font-style: var(--spectrum-detail-han-xl-strong-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-detail-han-xl-strong-text-font-weight, var(--spectrum-alias-han-heading-text-font-weight-regular-strong));
  letter-spacing: var(--spectrum-detail-han-xl-strong-text-letter-spacing, var(--spectrum-global-font-letter-spacing-medium));
  line-height: var(--spectrum-detail-han-xl-strong-text-line-height, var(--spectrum-alias-han-heading-text-line-height));
  text-transform: var(--spectrum-detail-han-xl-strong-text-transform, uppercase);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Detail--sizeL, .spectrum:lang(ko) .spectrum-Detail--sizeL, .spectrum:lang(zh) .spectrum-Detail--sizeL {
  font-size: var(--spectrum-detail-han-l-text-size, var(--spectrum-global-dimension-font-size-100));
  font-style: var(--spectrum-detail-han-l-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-detail-han-l-text-font-weight, var(--spectrum-alias-han-heading-text-font-weight-regular));
  letter-spacing: var(--spectrum-detail-han-l-text-letter-spacing, var(--spectrum-global-font-letter-spacing-medium));
  line-height: var(--spectrum-detail-han-l-text-line-height, var(--spectrum-alias-han-heading-text-line-height));
  text-transform: var(--spectrum-detail-han-l-text-transform, uppercase);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Detail--sizeL em, .spectrum:lang(ko) .spectrum-Detail--sizeL em, .spectrum:lang(zh) .spectrum-Detail--sizeL em {
  font-size: var(--spectrum-detail-han-l-emphasized-text-size, var(--spectrum-global-dimension-font-size-100));
  font-style: var(--spectrum-detail-han-l-emphasized-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-detail-han-l-emphasized-text-font-weight, var(--spectrum-alias-han-heading-text-font-weight-regular-emphasis));
  letter-spacing: var(--spectrum-detail-han-l-emphasized-text-letter-spacing, var(--spectrum-global-font-letter-spacing-medium));
  line-height: var(--spectrum-detail-han-l-emphasized-text-line-height, var(--spectrum-alias-han-heading-text-line-height));
  text-transform: var(--spectrum-detail-han-l-emphasized-text-transform, uppercase);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Detail--sizeL strong, .spectrum:lang(ko) .spectrum-Detail--sizeL strong, .spectrum:lang(zh) .spectrum-Detail--sizeL strong {
  font-size: var(--spectrum-detail-han-l-strong-text-size, var(--spectrum-global-dimension-font-size-100));
  font-style: var(--spectrum-detail-han-l-strong-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-detail-han-l-strong-text-font-weight, var(--spectrum-alias-han-heading-text-font-weight-regular-strong));
  letter-spacing: var(--spectrum-detail-han-l-strong-text-letter-spacing, var(--spectrum-global-font-letter-spacing-medium));
  line-height: var(--spectrum-detail-han-l-strong-text-line-height, var(--spectrum-alias-han-heading-text-line-height));
  text-transform: var(--spectrum-detail-han-l-strong-text-transform, uppercase);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Detail--sizeM, .spectrum:lang(ko) .spectrum-Detail--sizeM, .spectrum:lang(zh) .spectrum-Detail--sizeM {
  font-size: var(--spectrum-detail-han-m-text-size, var(--spectrum-global-dimension-font-size-75));
  font-style: var(--spectrum-detail-han-m-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-detail-han-m-text-font-weight, var(--spectrum-alias-han-heading-text-font-weight-regular));
  letter-spacing: var(--spectrum-detail-han-m-text-letter-spacing, var(--spectrum-global-font-letter-spacing-medium));
  line-height: var(--spectrum-detail-han-m-text-line-height, var(--spectrum-alias-han-heading-text-line-height));
  text-transform: var(--spectrum-detail-han-m-text-transform, uppercase);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Detail--sizeM em, .spectrum:lang(ko) .spectrum-Detail--sizeM em, .spectrum:lang(zh) .spectrum-Detail--sizeM em {
  font-size: var(--spectrum-detail-han-m-emphasized-text-size, var(--spectrum-global-dimension-font-size-75));
  font-style: var(--spectrum-detail-han-m-emphasized-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-detail-han-m-emphasized-text-font-weight, var(--spectrum-alias-han-heading-text-font-weight-regular-emphasis));
  letter-spacing: var(--spectrum-detail-han-m-emphasized-text-letter-spacing, var(--spectrum-global-font-letter-spacing-medium));
  line-height: var(--spectrum-detail-han-m-emphasized-text-line-height, var(--spectrum-alias-han-heading-text-line-height));
  text-transform: var(--spectrum-detail-han-m-emphasized-text-transform, uppercase);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Detail--sizeM strong, .spectrum:lang(ko) .spectrum-Detail--sizeM strong, .spectrum:lang(zh) .spectrum-Detail--sizeM strong {
  font-size: var(--spectrum-detail-han-m-strong-text-size, var(--spectrum-global-dimension-font-size-75));
  font-style: var(--spectrum-detail-han-m-strong-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-detail-han-m-strong-text-font-weight, var(--spectrum-alias-han-heading-text-font-weight-regular-strong));
  letter-spacing: var(--spectrum-detail-han-m-strong-text-letter-spacing, var(--spectrum-global-font-letter-spacing-medium));
  line-height: var(--spectrum-detail-han-m-strong-text-line-height, var(--spectrum-alias-han-heading-text-line-height));
  text-transform: var(--spectrum-detail-han-m-strong-text-transform, uppercase);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Detail--sizeS, .spectrum:lang(ko) .spectrum-Detail--sizeS, .spectrum:lang(zh) .spectrum-Detail--sizeS {
  font-size: var(--spectrum-detail-han-s-text-size, var(--spectrum-global-dimension-font-size-50));
  font-style: var(--spectrum-detail-han-s-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-detail-han-s-text-font-weight, var(--spectrum-alias-han-heading-text-font-weight-regular));
  letter-spacing: var(--spectrum-detail-han-s-text-letter-spacing, var(--spectrum-global-font-letter-spacing-medium));
  line-height: var(--spectrum-detail-han-s-text-line-height, var(--spectrum-alias-han-heading-text-line-height));
  text-transform: var(--spectrum-detail-han-s-text-transform, uppercase);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Detail--sizeS em, .spectrum:lang(ko) .spectrum-Detail--sizeS em, .spectrum:lang(zh) .spectrum-Detail--sizeS em {
  font-size: var(--spectrum-detail-han-s-emphasized-text-size, var(--spectrum-global-dimension-font-size-50));
  font-style: var(--spectrum-detail-han-s-emphasized-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-detail-han-s-emphasized-text-font-weight, var(--spectrum-alias-han-heading-text-font-weight-regular-emphasis));
  letter-spacing: var(--spectrum-detail-han-s-emphasized-text-letter-spacing, var(--spectrum-global-font-letter-spacing-medium));
  line-height: var(--spectrum-detail-han-s-emphasized-text-line-height, var(--spectrum-alias-han-heading-text-line-height));
  text-transform: var(--spectrum-detail-han-s-emphasized-text-transform, uppercase);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Detail--sizeS strong, .spectrum:lang(ko) .spectrum-Detail--sizeS strong, .spectrum:lang(zh) .spectrum-Detail--sizeS strong {
  font-size: var(--spectrum-detail-han-s-strong-text-size, var(--spectrum-global-dimension-font-size-50));
  font-style: var(--spectrum-detail-han-s-strong-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-detail-han-s-strong-text-font-weight, var(--spectrum-alias-han-heading-text-font-weight-regular-strong));
  letter-spacing: var(--spectrum-detail-han-s-strong-text-letter-spacing, var(--spectrum-global-font-letter-spacing-medium));
  line-height: var(--spectrum-detail-han-s-strong-text-line-height, var(--spectrum-alias-han-heading-text-line-height));
  text-transform: var(--spectrum-detail-han-s-strong-text-transform, uppercase);
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Detail--light, .spectrum:lang(ko) .spectrum-Detail--light, .spectrum:lang(zh) .spectrum-Detail--light {
  font-weight: var(--spectrum-detail-han-m-text-font-weight, var(--spectrum-alias-han-heading-text-font-weight-regular));
}

.spectrum:lang(ja) .spectrum-Detail--light .spectrum-Detail--emphasized, .spectrum:lang(ja) .spectrum-Detail--light em, .spectrum:lang(ko) .spectrum-Detail--light .spectrum-Detail--emphasized, .spectrum:lang(ko) .spectrum-Detail--light em, .spectrum:lang(zh) .spectrum-Detail--light .spectrum-Detail--emphasized, .spectrum:lang(zh) .spectrum-Detail--light em {
  font-style: var(--spectrum-detail-han-m-light-emphasized-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-detail-han-m-light-emphasized-text-font-weight, var(--spectrum-alias-han-heading-text-font-weight-light-emphasis));
}

.spectrum:lang(ja) .spectrum-Detail--light .spectrum-Detail--strong, .spectrum:lang(ja) .spectrum-Detail--light strong, .spectrum:lang(ko) .spectrum-Detail--light .spectrum-Detail--strong, .spectrum:lang(ko) .spectrum-Detail--light strong, .spectrum:lang(zh) .spectrum-Detail--light .spectrum-Detail--strong, .spectrum:lang(zh) .spectrum-Detail--light strong {
  font-style: var(--spectrum-detail-han-m-light-strong-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-detail-han-m-light-strong-text-font-weight, var(--spectrum-alias-han-heading-text-font-weight-light-strong));
}

.spectrum:lang(ja) .spectrum-Code--sizeXL, .spectrum:lang(ko) .spectrum-Code--sizeXL, .spectrum:lang(zh) .spectrum-Code--sizeXL {
  font-family: var(--spectrum-code-han-xl-text-font-family, var(--spectrum-alias-font-family-zh));
  font-size: var(--spectrum-code-han-xl-text-size, var(--spectrum-global-dimension-font-size-400));
  font-style: var(--spectrum-code-han-xl-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-code-han-xl-text-font-weight, var(--spectrum-alias-han-body-text-font-weight-regular));
  letter-spacing: var(--spectrum-code-han-xl-text-letter-spacing, var(--spectrum-global-font-letter-spacing-han));
  line-height: var(--spectrum-code-han-xl-text-line-height, var(--spectrum-alias-han-body-text-line-height));
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Code--sizeL, .spectrum:lang(ko) .spectrum-Code--sizeL, .spectrum:lang(zh) .spectrum-Code--sizeL {
  font-family: var(--spectrum-code-han-l-text-font-family, var(--spectrum-alias-font-family-zh));
  font-size: var(--spectrum-code-han-l-text-size, var(--spectrum-global-dimension-font-size-300));
  font-style: var(--spectrum-code-han-l-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-code-han-l-text-font-weight, var(--spectrum-alias-han-body-text-font-weight-regular));
  letter-spacing: var(--spectrum-code-han-l-text-letter-spacing, var(--spectrum-global-font-letter-spacing-han));
  line-height: var(--spectrum-code-han-l-text-line-height, var(--spectrum-alias-han-body-text-line-height));
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Code--sizeM, .spectrum:lang(ko) .spectrum-Code--sizeM, .spectrum:lang(zh) .spectrum-Code--sizeM {
  font-family: var(--spectrum-code-han-m-text-font-family, var(--spectrum-alias-font-family-zh));
  font-size: var(--spectrum-code-han-m-text-size, var(--spectrum-global-dimension-font-size-200));
  font-style: var(--spectrum-code-han-m-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-code-han-m-text-font-weight, var(--spectrum-alias-han-body-text-font-weight-regular));
  letter-spacing: var(--spectrum-code-han-m-text-letter-spacing, var(--spectrum-global-font-letter-spacing-han));
  line-height: var(--spectrum-code-han-m-text-line-height, var(--spectrum-alias-han-body-text-line-height));
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Code--sizeS, .spectrum:lang(ko) .spectrum-Code--sizeS, .spectrum:lang(zh) .spectrum-Code--sizeS {
  font-family: var(--spectrum-code-han-s-text-font-family, var(--spectrum-alias-font-family-zh));
  font-size: var(--spectrum-code-han-s-text-size, var(--spectrum-global-dimension-font-size-100));
  font-style: var(--spectrum-code-han-s-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-code-han-s-text-font-weight, var(--spectrum-alias-han-body-text-font-weight-regular));
  letter-spacing: var(--spectrum-code-han-s-text-letter-spacing, var(--spectrum-global-font-letter-spacing-han));
  line-height: var(--spectrum-code-han-s-text-line-height, var(--spectrum-alias-han-body-text-line-height));
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum:lang(ja) .spectrum-Code--sizeXS, .spectrum:lang(ko) .spectrum-Code--sizeXS, .spectrum:lang(zh) .spectrum-Code--sizeXS {
  font-family: var(--spectrum-code-han-xs-text-font-family, var(--spectrum-alias-font-family-zh));
  font-size: var(--spectrum-code-han-xs-text-size, var(--spectrum-global-dimension-font-size-75));
  font-style: var(--spectrum-code-han-xs-text-font-style, var(--spectrum-global-font-style-regular));
  font-weight: var(--spectrum-code-han-xs-text-font-weight, var(--spectrum-alias-han-body-text-font-weight-regular));
  letter-spacing: var(--spectrum-code-han-xs-text-letter-spacing, var(--spectrum-global-font-letter-spacing-han));
  line-height: var(--spectrum-code-han-xs-text-line-height, var(--spectrum-alias-han-body-text-line-height));
  margin-top: 0;
  margin-bottom: 0;
}

.spectrum-Heading--sizeXXXL {
  color: var(--spectrum-heading-xxxl-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum-Heading--sizeXXL {
  color: var(--spectrum-heading-xxl-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum-Heading--sizeXL {
  color: var(--spectrum-heading-xl-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum-Heading--sizeL {
  color: var(--spectrum-heading-l-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum-Heading--sizeM {
  color: var(--spectrum-heading-m-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum-Heading--sizeS {
  color: var(--spectrum-heading-s-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum-Heading--sizeXS {
  color: var(--spectrum-heading-xs-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum-Heading--sizeXXS {
  color: var(--spectrum-heading-xxs-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum-Heading-sizeXXXL--light {
  color: var(--spectrum-heading-xxxl-light-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum-Heading-sizeXXL--light {
  color: var(--spectrum-heading-xxl-light-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum-Heading-sizeXL--light {
  color: var(--spectrum-heading-xl-light-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum-Heading-sizeL--light {
  color: var(--spectrum-heading-l-light-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum-Heading-sizeXXXL--heavy {
  color: var(--spectrum-heading-xxxl-heavy-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum-Heading-sizeXXL--heavy {
  color: var(--spectrum-heading-xxl-heavy-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum-Heading-sizeXL--heavy {
  color: var(--spectrum-heading-xl-heavy-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum-Heading-sizeL--heavy {
  color: var(--spectrum-heading-l-heavy-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum-Heading-sizeXXXL--heading {
  color: var(--spectrum-heading-xxxl-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum-Heading-sizeXXL--heading {
  color: var(--spectrum-heading-xxl-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum-Heading-sizeXL--heading {
  color: var(--spectrum-heading-xl-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum-Heading-sizeL--heading {
  color: var(--spectrum-heading-l-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum-Body--sizeXXXL {
  color: var(--spectrum-body-xxxl-text-color, var(--spectrum-alias-text-color));
}

.spectrum-Body--sizeXXL {
  color: var(--spectrum-body-xxl-text-color, var(--spectrum-alias-text-color));
}

.spectrum-Body--sizeXL {
  color: var(--spectrum-body-xl-text-color, var(--spectrum-alias-text-color));
}

.spectrum-Body--sizeL {
  color: var(--spectrum-body-l-text-color, var(--spectrum-alias-text-color));
}

.spectrum-Body--sizeM {
  color: var(--spectrum-body-m-text-color, var(--spectrum-alias-text-color));
}

.spectrum-Body--sizeS {
  color: var(--spectrum-body-s-text-color, var(--spectrum-alias-text-color));
}

.spectrum-Body--sizeXS {
  color: var(--spectrum-body-xs-text-color, var(--spectrum-alias-text-color));
}

.spectrum-Detail--sizeXL {
  color: var(--spectrum-detail-xl-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum-Detail--sizeL {
  color: var(--spectrum-detail-l-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum-Detail--sizeM {
  color: var(--spectrum-detail-m-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum-Detail--sizeS {
  color: var(--spectrum-detail-s-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum-Code--sizeXL {
  color: var(--spectrum-code-xl-text-color, var(--spectrum-alias-text-color));
}

.spectrum-Code--sizeL {
  color: var(--spectrum-code-l-text-color, var(--spectrum-alias-text-color));
}

.spectrum-Code--sizeM {
  color: var(--spectrum-code-m-text-color, var(--spectrum-alias-text-color));
}

.spectrum-Code--sizeS {
  color: var(--spectrum-code-s-text-color, var(--spectrum-alias-text-color));
}

.spectrum-Code--sizeXS {
  color: var(--spectrum-code-xs-text-color, var(--spectrum-alias-text-color));
}

.spectrum:lang(ja) .spectrum-Body--sizeXXXL, .spectrum:lang(ko) .spectrum-Body--sizeXXXL, .spectrum:lang(zh) .spectrum-Body--sizeXXXL {
  color: var(--spectrum-body-han-xxxl-text-color, var(--spectrum-alias-text-color));
}

.spectrum:lang(ja) .spectrum-Body--sizeXXL, .spectrum:lang(ko) .spectrum-Body--sizeXXL, .spectrum:lang(zh) .spectrum-Body--sizeXXL {
  color: var(--spectrum-body-han-xxl-text-color, var(--spectrum-alias-text-color));
}

.spectrum:lang(ja) .spectrum-Body--sizeXL, .spectrum:lang(ko) .spectrum-Body--sizeXL, .spectrum:lang(zh) .spectrum-Body--sizeXL {
  color: var(--spectrum-body-han-xl-text-color, var(--spectrum-alias-text-color));
}

.spectrum:lang(ja) .spectrum-Body--sizeL, .spectrum:lang(ko) .spectrum-Body--sizeL, .spectrum:lang(zh) .spectrum-Body--sizeL {
  color: var(--spectrum-body-han-l-text-color, var(--spectrum-alias-text-color));
}

.spectrum:lang(ja) .spectrum-Body--sizeM, .spectrum:lang(ko) .spectrum-Body--sizeM, .spectrum:lang(zh) .spectrum-Body--sizeM {
  color: var(--spectrum-body-han-m-text-color, var(--spectrum-alias-text-color));
}

.spectrum:lang(ja) .spectrum-Body--sizeS, .spectrum:lang(ko) .spectrum-Body--sizeS, .spectrum:lang(zh) .spectrum-Body--sizeS {
  color: var(--spectrum-body-han-s-text-color, var(--spectrum-alias-text-color));
}

.spectrum:lang(ja) .spectrum-Body--sizeXS, .spectrum:lang(ko) .spectrum-Body--sizeXS, .spectrum:lang(zh) .spectrum-Body--sizeXS {
  color: var(--spectrum-body-han-xs-text-color, var(--spectrum-alias-text-color));
}

.spectrum:lang(ja) .spectrum-Heading--sizeXXXL, .spectrum:lang(ko) .spectrum-Heading--sizeXXXL, .spectrum:lang(zh) .spectrum-Heading--sizeXXXL {
  color: var(--spectrum-heading-xxxl-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum:lang(ja) .spectrum-Heading--sizeXXL, .spectrum:lang(ko) .spectrum-Heading--sizeXXL, .spectrum:lang(zh) .spectrum-Heading--sizeXXL {
  color: var(--spectrum-heading-xxl-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum:lang(ja) .spectrum-Heading--sizeXL, .spectrum:lang(ko) .spectrum-Heading--sizeXL, .spectrum:lang(zh) .spectrum-Heading--sizeXL {
  color: var(--spectrum-heading-xl-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum:lang(ja) .spectrum-Heading--sizeL, .spectrum:lang(ko) .spectrum-Heading--sizeL, .spectrum:lang(zh) .spectrum-Heading--sizeL {
  color: var(--spectrum-heading-l-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum:lang(ja) .spectrum-Heading--sizeM, .spectrum:lang(ko) .spectrum-Heading--sizeM, .spectrum:lang(zh) .spectrum-Heading--sizeM {
  color: var(--spectrum-heading-m-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum:lang(ja) .spectrum-Heading--sizeS, .spectrum:lang(ko) .spectrum-Heading--sizeS, .spectrum:lang(zh) .spectrum-Heading--sizeS {
  color: var(--spectrum-heading-s-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum:lang(ja) .spectrum-Heading--sizeXS, .spectrum:lang(ko) .spectrum-Heading--sizeXS, .spectrum:lang(zh) .spectrum-Heading--sizeXS {
  color: var(--spectrum-heading-xs-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum:lang(ja) .spectrum-Heading--sizeXXS, .spectrum:lang(ko) .spectrum-Heading--sizeXXS, .spectrum:lang(zh) .spectrum-Heading--sizeXXS {
  color: var(--spectrum-heading-xxs-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum:lang(ja) .spectrum-Heading-sizeXXXL--light, .spectrum:lang(ko) .spectrum-Heading-sizeXXXL--light, .spectrum:lang(zh) .spectrum-Heading-sizeXXXL--light {
  color: var(--spectrum-heading-xxxl-light-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum:lang(ja) .spectrum-Heading-sizeXXL--light, .spectrum:lang(ko) .spectrum-Heading-sizeXXL--light, .spectrum:lang(zh) .spectrum-Heading-sizeXXL--light {
  color: var(--spectrum-heading-xxl-light-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum:lang(ja) .spectrum-Heading-sizeXL--light, .spectrum:lang(ko) .spectrum-Heading-sizeXL--light, .spectrum:lang(zh) .spectrum-Heading-sizeXL--light {
  color: var(--spectrum-heading-xl-light-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum:lang(ja) .spectrum-Heading-sizeL--light, .spectrum:lang(ko) .spectrum-Heading-sizeL--light, .spectrum:lang(zh) .spectrum-Heading-sizeL--light {
  color: var(--spectrum-heading-l-light-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum:lang(ja) .spectrum-Heading-sizeXXXL--heavy, .spectrum:lang(ko) .spectrum-Heading-sizeXXXL--heavy, .spectrum:lang(zh) .spectrum-Heading-sizeXXXL--heavy {
  color: var(--spectrum-heading-xxxl-heavy-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum:lang(ja) .spectrum-Heading-sizeXXL--heavy, .spectrum:lang(ko) .spectrum-Heading-sizeXXL--heavy, .spectrum:lang(zh) .spectrum-Heading-sizeXXL--heavy {
  color: var(--spectrum-heading-xxl-heavy-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum:lang(ja) .spectrum-Heading-sizeXL--heavy, .spectrum:lang(ko) .spectrum-Heading-sizeXL--heavy, .spectrum:lang(zh) .spectrum-Heading-sizeXL--heavy {
  color: var(--spectrum-heading-xl-heavy-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum:lang(ja) .spectrum-Heading-sizeL--heavy, .spectrum:lang(ko) .spectrum-Heading-sizeL--heavy, .spectrum:lang(zh) .spectrum-Heading-sizeL--heavy {
  color: var(--spectrum-heading-l-heavy-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum:lang(ja) .spectrum-Heading-sizeXXXL--heading, .spectrum:lang(ko) .spectrum-Heading-sizeXXXL--heading, .spectrum:lang(zh) .spectrum-Heading-sizeXXXL--heading {
  color: var(--spectrum-heading-xxxl-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum:lang(ja) .spectrum-Heading-sizeXXL--heading, .spectrum:lang(ko) .spectrum-Heading-sizeXXL--heading, .spectrum:lang(zh) .spectrum-Heading-sizeXXL--heading {
  color: var(--spectrum-heading-xxl-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum:lang(ja) .spectrum-Heading-sizeXL--heading, .spectrum:lang(ko) .spectrum-Heading-sizeXL--heading, .spectrum:lang(zh) .spectrum-Heading-sizeXL--heading {
  color: var(--spectrum-heading-xl-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum:lang(ja) .spectrum-Heading-sizeL--heading, .spectrum:lang(ko) .spectrum-Heading-sizeL--heading, .spectrum:lang(zh) .spectrum-Heading-sizeL--heading {
  color: var(--spectrum-heading-l-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum:lang(ja) .spectrum-Detail--sizeXL, .spectrum:lang(ko) .spectrum-Detail--sizeXL, .spectrum:lang(zh) .spectrum-Detail--sizeXL {
  color: var(--spectrum-detail-xl-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum:lang(ja) .spectrum-Detail--sizeL, .spectrum:lang(ko) .spectrum-Detail--sizeL, .spectrum:lang(zh) .spectrum-Detail--sizeL {
  color: var(--spectrum-detail-l-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum:lang(ja) .spectrum-Detail--sizeM, .spectrum:lang(ko) .spectrum-Detail--sizeM, .spectrum:lang(zh) .spectrum-Detail--sizeM {
  color: var(--spectrum-detail-m-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum:lang(ja) .spectrum-Detail--sizeS, .spectrum:lang(ko) .spectrum-Detail--sizeS, .spectrum:lang(zh) .spectrum-Detail--sizeS {
  color: var(--spectrum-detail-s-text-color, var(--spectrum-alias-heading-text-color));
}

.spectrum:lang(ja) .spectrum-Code--sizeXL, .spectrum:lang(ko) .spectrum-Code--sizeXL, .spectrum:lang(zh) .spectrum-Code--sizeXL {
  color: var(--spectrum-code-xl-text-color, var(--spectrum-alias-text-color));
}

.spectrum:lang(ja) .spectrum-Code--sizeL, .spectrum:lang(ko) .spectrum-Code--sizeL, .spectrum:lang(zh) .spectrum-Code--sizeL {
  color: var(--spectrum-code-l-text-color, var(--spectrum-alias-text-color));
}

.spectrum:lang(ja) .spectrum-Code--sizeM, .spectrum:lang(ko) .spectrum-Code--sizeM, .spectrum:lang(zh) .spectrum-Code--sizeM {
  color: var(--spectrum-code-m-text-color, var(--spectrum-alias-text-color));
}

.spectrum:lang(ja) .spectrum-Code--sizeS, .spectrum:lang(ko) .spectrum-Code--sizeS, .spectrum:lang(zh) .spectrum-Code--sizeS {
  color: var(--spectrum-code-s-text-color, var(--spectrum-alias-text-color));
}

.spectrum:lang(ja) .spectrum-Code--sizeXS, .spectrum:lang(ko) .spectrum-Code--sizeXS, .spectrum:lang(zh) .spectrum-Code--sizeXS {
  color: var(--spectrum-code-xs-text-color, var(--spectrum-alias-text-color));
}

.spectrum, .spectrum-Body {
  color: var(--spectrum-body-m-text-color, var(--spectrum-alias-text-color));
}

/*# sourceMappingURL=index.42806576.css.map */
